'use client';
import { useContext, useEffect, useRef, useState } from 'react';
import type { ICBCVideoplayer } from '@cbc/videoplayer';
import type CbcVideoplayer from '@cbc/videoplayer/src/cbcVideoplayer';
import type { ISSUER } from '@cbc/videoplayer/src/videoManager/types';
import { AppContext } from '../../../context/AppContext';
import { useConsent } from '../../../customHooks/useConsent';
import { useConsentedVendorIds } from '../../../customHooks/useConsentedVendorIds';
import { useConsentReady } from '../../../customHooks/useConsentReady';
import { useIsAppView } from '../../../customHooks/useIsAppView';
import { useIsMobile } from '../../../customHooks/useIsMobile';
import { useQueryExist } from '../../../customHooks/useQueryExist';
import { useTogglePreroll } from '../../../customHooks/useTogglePreroll';
import type { PlayerEvent } from '../../../types/video';
import { UUID } from '../../../utils/idGenerator';
import { waitFor } from '../../../utils/promise';
import { VideoContentStarted } from '../../lib/GoogleTagManager';
import type { ApiImageProps } from '../Image';
import { VideoPlayerControls } from '../VideoPlayerControls/VideoPlayerControls';
import {
  createLoadVideoConfig,
  createPlayerConfig,
  dateErrorMessage,
  defaultErrorMessage,
  geoBlockingErrorMessage,
} from './config';
import { geoAllowed, getTargeting, getVideoDuration } from './helper/utils';
import styles from './VideoPlayerDefault.module.scss';
import VideoPlayerPoster from './VideoPlayerPoster';

declare global {
  interface Window {
    $AC?: {
      loadConfig?: () => void;
      getSXPKeys?: () => string;
      setTargeting?: (e?: unknown, t?: unknown, i?: unknown) => void;
    };
  }
}

export type GeoCountries = 'de' | 'dach' | 'dachlilu' | 'world';
export const COUNTRIES_DACH = ['de', 'at', 'ch'];
export const COUNTRIES_DACHLILU = ['de', 'at', 'ch', 'li', 'lu'];

export type VideoTags = {
  readonly name: string;
  readonly id: string;
};

export type VideoEncoding = {
  readonly value: {
    readonly duration: {
      readonly value: string;
    };
    readonly 'content-url': {
      readonly value: string;
    };
    readonly 'encoding-format': {
      readonly value: string;
    };
  };
};

export type VideoProps = {
  readonly id: string;
  readonly sourceId: string;
  readonly lastmodifiedAt: string;
  readonly createdAt: string;
  readonly publishedAt: string;
  readonly type: string;
  readonly fields: {
    readonly title: string;
    readonly subtitle?: string;
    readonly 'first-title'?: string;
    readonly 'first-subtitle'?: string;
    readonly text?: string;
    readonly description?: string;
    readonly contributor?: string;
    readonly 'ads-free': string;
    readonly producer: string;
    readonly 'regions-allowed': GeoCountries;
    readonly encoding?: Array<VideoEncoding>;
  };
  readonly image: ApiImageProps;
  readonly url: string;
  readonly tags?: Array<VideoTags>;
  readonly related?: Array<VideoProps>;
};

export type VideoPlayerDefaultProps = {
  video: VideoProps;
  startType?: number;
  onEvents?: (event: PlayerEvent, videoId: string) => void;
  noPreroll?: boolean;
  noPostroll?: boolean;
};

export function getPlayerEventsCallback(
  onEvents: ((event: PlayerEvent, videoId: string) => void) | undefined,
  currentVideo: React.MutableRefObject<VideoProps>,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) {
  return (e: PlayerEvent) => {
    onEvents?.(e, currentVideo.current.id);

    if (e.type === 'onPlaying') {
      setIsPlaying(true);
    }

    if (e.type === 'onContentStart') {
      VideoContentStarted(currentVideo.current);
    }
  };
}

export function playerReadyCallback(callback: () => void) {
  return (e: { type: string }) => {
    if (e.type === 'onPlayerReady') {
      callback();
    }
  };
}

const VideoPlayerDefault = ({
  video,
  startType = 0,
  onEvents,
  noPreroll = false,
  noPostroll = false,
}: VideoPlayerDefaultProps) => {
  const {
    pageMetaData: { noVideoAds },
  } = useContext(AppContext);
  const playerId = useRef<string>(UUID());
  const videoElementId = `video-element-${playerId.current}`;
  const currentVideo = useRef<VideoProps>(video);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const [player, setPlayer] = useState<CbcVideoplayer | null>(null);
  const isAppView = useIsAppView();
  const isVideoDebug = useQueryExist('video.debug');
  const isMobile = useIsMobile();
  const { consent } = useConsent();
  const consentReady = useConsentReady();
  const consentedVendorIds = useConsentedVendorIds();

  const {
    analyticsData: { ivwCode },
  } = useContext(AppContext);
  const { tcString } = consent;
  const [isVideoAllowed, setIsVideoAllowed] = useState<boolean>(true);
  const [videoErrorMessage, setVideoErrorMessage] = useState<string | null>(null);
  const [isGeoAllowed, setIsGeoAllowed] = useState<boolean>(true);
  const [isDateAllowed, setIsDateAllowed] = useState<boolean>(true);
  const agofCode = isAppView ? 'dbrsmaf_ten_rtlvideo' : 'dbrsowf_ten_rtlvideo';
  const adsDisabled = useQueryExist('video.adsOff') || noVideoAds || video.fields['ads-free'] === 'true';

  const loadVideo = (player: CbcVideoplayer, startType: number) => {
    setTimeout(async () => {
      currentVideo.current = video;

      let targeting = null;

      await waitFor(() => typeof window.$AC !== 'undefined', 5000)
        .then(() => {
          targeting = getTargeting(getVideoDuration(video.fields.encoding));
        })
        .catch(() => {
          console.error('TIMEOUT LOADING IPDVDC');
        });

      await player.loadVideo(
        createLoadVideoConfig({
          video: video,
          targeting,
          tcString,
          startType,
          ivw: ivwCode,
          agof: agofCode,
          ads: !adsDisabled,
          noPreroll: noPreroll || adsDisabled,
          noPostroll: noPostroll || adsDisabled,
        })
      );
    }, 0);
  };

  const createPlayer = async (CBCVideoplayer: ICBCVideoplayer) => {
    const playerConfig = createPlayerConfig(
      consentedVendorIds,
      video.fields.contributor,
      isMobile ? 'Smartphone' : 'Online',
      isVideoDebug
    );

    const newPlayer = CBCVideoplayer.create(playerId.current, playerConfig, videoElementId);

    setPlayer(newPlayer);

    return newPlayer;
  };

  useEffect(() => {
    if (consentReady && isVideoAllowed) {
      if (!currentVideo.current.fields.encoding) {
        return;
      }

      fetch('/proxy/geoip/', { next: { revalidate: 1 } })
        .then((res) => res.json())
        .then((data) => {
          setIsGeoAllowed(geoAllowed(video.fields['regions-allowed'], data.country));
        });

      import('@cbc/videoplayer').then((module) => {
        const CBCVideoplayer = module.default;

        createPlayer(CBCVideoplayer).then((player) => {
          CBCVideoplayer.onEvent(playerId.current, getPlayerEventsCallback(onEvents, currentVideo, setIsPlaying));

          loadVideo(player, startType);

          CBCVideoplayer.onEvent(
            playerId.current,
            playerReadyCallback(() => {
              if (startType === 1) {
                player.play('', 'ui-client' as ISSUER);
              }
            })
          );
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentReady, isVideoAllowed]);

  useEffect(() => {
    if (!player) {
      return;
    }

    loadVideo(player, startType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video.id]);

  useEffect(() => {
    setIsDateAllowed(!!currentVideo.current.fields.encoding);
  }, [currentVideo]);

  useEffect(() => {
    if (!isGeoAllowed) {
      setIsVideoAllowed(isGeoAllowed);
      setVideoErrorMessage(geoBlockingErrorMessage);
    }
    if (!isDateAllowed) {
      setIsVideoAllowed(isDateAllowed);
      setVideoErrorMessage(dateErrorMessage);
    }
  }, [isGeoAllowed, isDateAllowed]);

  const pausedOnReturnToVideo = useRef<boolean>(false);
  const playerElementRef = useRef(null);
  useTogglePreroll(player, playerElementRef, pausedOnReturnToVideo);

  return isVideoAllowed ? (
    <div className={styles.root}>
      {!isPlaying && (
        <VideoPlayerPoster className={styles.poster} image={video.image} altText={video.fields.title ?? ''} />
      )}
      <div id={playerId.current}>
        <video
          ref={playerElementRef}
          id={videoElementId}
          preload="metadata"
          data-testid={`videoplayer-video-${video.id}`}
        >
          <track kind="captions" />
        </video>
        <VideoPlayerControls player={player} pausedOnReturnToVideo={pausedOnReturnToVideo} />
      </div>
    </div>
  ) : (
    <div className={styles.errorMessage}>{videoErrorMessage ?? defaultErrorMessage}</div>
  );
};

export default VideoPlayerDefault;
